import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
 
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";

import Navigationbar from "components/Final/Headers/Navigationbar";
import QualityHeader from "./quality-section/QualityHeader";
import Footer from "components/Final/Headers/Footer";
import QualitySection from "./quality-section/QualitySection";
import WorkWith from "./home-sections/WorkWith";
import AboutUsContact from "./aboutus-sections/AboutUsContact";
import WorkProcess from "./quality-section/WorkProcess";

function QualityPage() {

  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Navigationbar />
      <div className="wrapper">
        <QualityHeader />
        <QualitySection/>
        <WorkWith/>
        {/* <WorkProcess/> */}
        <AboutUsContact/>
        <Footer />
      </div>
    </>
  );
}

export default QualityPage;
