import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function QualitySection() {
  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
          <Container fluid>
              <Col md="12">
                <h2 className="title text-center">Quality</h2>
                <div className="blogs-1 pt-0 pb-0" id="blogs-1">
                  <Row>
                    <Col className="ml-auto mr-auto" md="10">
                      <Card className="card-plain card-blog">
                        <Row>
                        <h5 className="description" style={{fontWeight:"400" , color:"black", textAlign: "justify"}}>
                        At TJ Suspension Systems, quality isn't just a priority; it's our cornerstone. Our commitment to excellence begins with a dedicated team of quality controllers, well-versed in industry requirements, ensuring the delivery of the finest products to our customers. Stringent checks on well-defined parameters are the norm, maintaining unparalleled quality. Our adept quality auditors, always attuned to customer needs, go beyond production and delivery. From selecting premium raw materials to testing the finished goods, every product undergoes rigorous quality control at each stage.
                        </h5>
                        <h5 className="description" style={{fontWeight:"400" , color:"black", textAlign: "justify"}}>
                        Our role doesn't stop at meeting specifications; it transcends to exceeding expectations. We take pride in our ability to meet deadlines without compromising the impeccable quality that defines TJ Suspension Systems.
                        </h5>
                       
                        </Row>
                        <Row className="text-center">
                          <Col sm="6" md="6" lg="3" className="text-center mt-3">
                            <div className="card-image">
                              <img
                                alt="..."
                                height={300}
                                className="img img-raised rounded"
                                src={require("assets/images/cer1.PNG")}
                              ></img>
                            </div>
                          </Col>
                          <Col sm="6" md="6" lg="3" className="text-center mt-3">
                            <div className="card-image">
                              <img
                                alt="..."
                                height={300}
                                className="img img-raised rounded"
                                src={require("assets/images/cer2.PNG")}
                              ></img>
                            </div>
                          </Col>
                          <Col sm="6" md="6" lg="3" className="text-center mt-3">
                            <div className="card-image">
                              <img
                                alt="..."
                                height={300}
                                className="img img-raised rounded"
                                src={require("assets/images/cer3.PNG")}
                              ></img>
                            </div>
                          </Col>
                          <Col sm="6" md="6" lg="3" className="text-center mt-3">
                            <div className="card-image">
                              <img
                                alt="..."
                                height={300}
                                className="img img-raised rounded"
                                src={require("assets/images/cer4.PNG")}
                              ></img>
                            </div>
                          </Col>
                          </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default QualitySection;
