import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
 
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DropdownFixedNavbar from "components/Navbars/DropdownFixedNavbar.js";

import Navigationbar from "components/Final/Headers/Navigationbar";
import QualityHeader from "./quality-section/QualityHeader";
import Footer from "components/Final/Headers/Footer";
import ProductsSection from "./products-section/ProductsSection";
import AboutUsContact from "./aboutus-sections/AboutUsContact";
import ProductHeader from "./products-section/ProductHeader";

function Products() {

  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Navigationbar />
      <div className="wrapper">
        <ProductHeader />
        {/* <section id="product"> */}
        <ProductsSection/>
        {/* </section> */}
        <AboutUsContact/>
        <Footer />
      </div>
    </>
  );
}

export default Products;
