import React, { useState } from "react";
// react plugin used to create google maps

import ReCAPTCHA from "react-google-recaptcha";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function ContactUs() {
  const [first1Focus, setFirst1Focus] = useState(false);
  const [last1Focus, setLast1Focus] = useState(false);
  const [email1Focus, setEmail1Focus] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    message: '',
    recaptcha: '',
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // Clear validation error when the user starts typing
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'First name is required';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }

    // if (!formData.recaptcha) {
    //   errors.recaptcha = 'Please confirm that you are not a robot';
    // }

    console.log("errors",errors);
    setValidationErrors(errors);

    return Object.keys(errors).length === 0; // Form is valid if there are no errors
  };

  const handleSubmit = (e) => {
    // Validate the form but don't prevent default submission
    const valid = validateForm();

    // Log the data only if the form is valid
    if (valid) {
      console.log('Form data:', formData);
      // handleSendEmail();
    }
  };

  // const [to, setTo] = useState('utkarsh.kaliba@gmail.com');
  // const [subject, setSubject] = useState('testing');
  // const [text, setText] = useState('testing text');

  // const handleSendEmail = async () => {
  //   try {
  //     await sendEmail({
  //       to: 'utkarsh.kaliba@gmail.com',
  //       from: 'tjsuspensionsystemspvtltd@gmail.com',
  //       subject: 'Hello from Next.js',
  //       message: 'This is a test email sent from Next.js using Sendinblue.',
  //     });
  //     console.log('Email sent successfully!');
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //     // Handle error
  //   }
  //   // try {
  //   //   // You can use fetch or axios to send a POST request to your server
  //   //   const response = await fetch('http://localhost:3001/send-email', {
  //   //     method: 'POST',
  //   //     headers: {
  //   //       'Content-Type': 'application/json',
  //   //     },
  //   //     body: JSON.stringify({ to, subject, text }),
  //   //   });

  //   //   if (response.ok) {
  //   //     console.log('Email sent successfully!');
  //   //   } else {
  //   //     console.error('Failed to send email');
  //   //   }
  //   // } catch (error) {
  //   //   console.error('Error sending email:', error);
  //   // }
  // };

 
  
  return (
    <>
      <div className="cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/images/tj/About-Us_1440x961.jpg") + ")"
            // backgroundImage:"url()"
          }}
        >
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title">Get in Touch</h2>
                <h5 className="description">
                Reach out to us, your inquiries are our priority. Let's start the conversation towards excellence!
                </h5>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at the office</h4>
                    <p className="description">
                      U-63/ U-64, MIDC Industrial Area, Hingna<br/> 
                      Nagpur – 440016,<br></br>
                      Maharashtra, India
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Give us a ring</h4>
                    <p className="description">
                      +91-976 477 9736 <br></br>
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-contact card-raised">
                  <Form id="contact-form1" role="form">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Contact Us</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="pr-2" md="6">
                          <label>First name</label>
                          <InputGroup
                            className={first1Focus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              aria-label="First Name..."
                              autoComplete="given-name"
                              placeholder="First Name..."
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              onFocus={() => setFirst1Focus(true)}
                              onBlur={() => setFirst1Focus(false)}
                              required
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col className="pl-2" md="6">
                          <FormGroup>
                            <label>Last name</label>
                            <InputGroup
                              className={last1Focus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons text_caps-small"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                aria-label="Last Name..."
                                autoComplete="family-name"
                                placeholder="Last Name..."
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                onFocus={() => setLast1Focus(true)}
                                onBlur={() => setLast1Focus(false)}
                                required
                              ></Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <label>Email address</label>
                        <InputGroup
                          className={email1Focus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email Here..."
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            onFocus={() => setEmail1Focus(true)}
                            onBlur={() => setEmail1Focus(false)}
                            required
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Your message</label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Col md="6">
                          <FormGroup >
                          <ReCAPTCHA
                              sitekey="6Lcu1FspAAAAABa-SNs3L9vPHVfJFIif8xMqh7V-"
                              onChange={(value) => setFormData({ ...formData, recaptcha: value })}
                          />
                            {/* <Label check>
                              <Input type="checkbox"></Input>
                              <span className="form-check-sign"></span>
                              I'm not a robot
                            </Label> */}
                          </FormGroup>
                        </Col>
                        </Row>
                        {/* {validationErrors.name && <div className="error-message">{validationErrors.name}</div>}
      {validationErrors.lastName && <div className="error-message">{validationErrors.lastName}</div>}
      {validationErrors.email && <div className="error-message">{validationErrors.email}</div>}
      {validationErrors.message && <div className="error-message">{validationErrors.message}</div>}
      {validationErrors.isNotRobot && (
        <div className="error-message">{validationErrors.isNotRobot}</div>
      )} */}
                        <Row>
                        <Col md="6"></Col>
                        <Col md="6">
                          <Button
                            className="btn-round pull-right"
                            color="info"
                            // type="submit"
                            onClick={handleSubmit}
                          >
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
       
      </div>
    </>
  );
}

export default ContactUs;
