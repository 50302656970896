import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function WorkWith() {
  return (
    <>
      <div className="cd-section" id="features">
        
        
        
      <div
          className="features-2 section-image"
          style={{
            backgroundImage: "url(" + require("assets/images/sky.jpg") + ")",
          }}
        >
          <Container fluid>
            <Row>
              <Col className="mr-auto ml-auto" md="8">
                <h2 className="title">Industry We Serve</h2>
                <h5 className="description">
                TJ Suspension Systems stands as a cornerstone, delivering tailor-made coiled spring that redefine standards. Our commitment to excellence extends across sectors. The backbone of progress, fortifying agriculture's productivity, propelling innovation on sleek highways, forming a robust industrial framework, and powering precision in the realm of electricity. Our coiled springs redefine reliability across diverse sectors.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-info icon-circle d-flex align-items-center justify-content-center">
                  <img src={require("assets/images/agriculture.png")} height={60}/>

                  </div>
                  <h4 className="info-title">Agriculture</h4>
                  <p className="description">
                  In agriculture, our coiled springs cultivate efficiency and durability, ensuring that each harvest reaps the benefits of strength and resilience.
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-danger icon-circle d-flex align-items-center justify-content-center">
                  <img src={require("assets/images/automobile.png")} height={60}/>

                  </div>
                  <h4 className="info-title">Automobile</h4>
                  <p className="description">
                  Our contributions to the automobile industry drive innovation forward, providing robust and reliable components that accelerate performance.
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-warning icon-circle d-flex align-items-center justify-content-center">
                  <img src={require("assets/images/industrial.png")} height={50} alt="automobile" />

                  </div>
                  <h4 className="info-title">Industrial</h4>
                  <p className="description">
                  In the industrial sector, TJ's suspension  products form the backbone of reliability, enhancing structural integrity and efficiency across diverse applications.
                  </p>
                </div>
              </Col>

              <Col md="3">
  <div className="info text-center">
    <div className="icon icon-warning icon-circle d-flex align-items-center justify-content-center">
      <img
        src={require("assets/images/electrical.png")}
        height={60}
        alt="Electrical Icon"
      />
    </div>
    <h4 className="info-title">Electrical</h4>
    <p className="description">
    Tj’s suspension sollutions supports powering of the electrical industries and ensuring durability and precision in electrical applications.
    </p>
  </div>
</Col>

            </Row>
          </Container>
        </div>
        
      
        
      </div>
    </>
  );
}

export default WorkWith;
