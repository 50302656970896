import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function AboutUsSection() {
  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
        <Container fluid>
        <h2 className="title text-center">About Us</h2>
        <div className="section section-basic-components pt-0">
        <Container>
          <Row>
          <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  // height={450}
                  style={{maxHeight: "450"}}
                  className="components-macbook"
                  src={require("assets/images/tj/About-Us_525x368.jpg")}
                ></img>
                
              </div>
            </Col>
            <Col className="ml-auto mr-auto" lg="6" md="12">
              <p className="description mt-2 " style={{ fontWeight:"400" , color:"black", textAlign: "justify" }}>
              TJ Suspension Systems, a leading force in manufacturing and supplying a world-class range of springs and bars. As a trusted brand, we focus relentlessly on meeting the unique demands of our customers, ensuring prompt delivery at competitive prices with unwavering quality. The inception of "TJ Suspension Systems" was driven by the rising demand for leaf springs and coil springs. In the fiercely competitive market, our commitment to delivering top-notch products and a customer-friendly approach has earned us trusted brand recognition.
              </p>

              <p className="description" style={{ fontWeight:"400" , color:"black", textAlign: "justify"  }}>
              Our success lies in creating loyal customers through an unwavering commitment to quality and excellence. Specializing in crafting custom-made springs tailored to customer drawings, we stand at the forefront of innovation in the suspension system industry.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
        </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsSection;
