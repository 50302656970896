import React from "react";


import Header from "./home-sections/Header.js";
import Footer from "components/Final/Headers/Footer.js";
import Navigationbar from "components/Final/Headers/Navigationbar.js";
import Products from "./home-sections/Products.js";
import ContactUs from "./home-sections/ContactUs.js";
import AboutUs from "./home-sections/AboutUs.js";
import WorkWith from "./home-sections/WorkWith.js";

function Home() {
  React.useEffect(() => {
    // document.body.classList.add("");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      // document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Navigationbar />
      {/* <div className="wrapper"> */}
        <Header />
        <div className="main">
          <section id="about">
        <AboutUs />
          </section>
          <section id="workwithus">
          <WorkWith/>
          </section>
          <section id="product">
          <Products />
          </section>
       
          <section id="contactus">
          <ContactUs />
          </section>
          
          <Footer /> 
        </div>
      {/* </div> */}
    </>
  );
}

export default Home;
