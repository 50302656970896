import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function Navigationbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : ""
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? "info"
      : "neutral"
  );
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg" style={{ paddingTop:2, height: '80px' }}>
        <Container fluid>
          <div className="navbar-translate"  style={{ paddingTop:"1%" }}>
          <NavLink tag={Link} to="/home">
            <NavbarBrand id="navbar-brand">
              <img src="/tj-logo-horizontal.png" height={30} />
            </NavbarBrand>
          </NavLink>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>

              <NavItem>
                <NavLink tag={Link} to="/home">
                  Home
                </NavLink>
             </NavItem>

              <NavItem>
                <NavLink href="/aboutUs" >
                  About Us
                </NavLink>
              </NavItem>

              <NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    href="#pablo"
                    id="navbarDropdownMenuLink1"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                
                    <p>Products</p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                    <DropdownItem href="/products#block_barrel_spring">
                    Block/ Barrel Spring
                    </DropdownItem>
                    <DropdownItem href="/products#coil_spring">
                    Coil Spring 
                    </DropdownItem>
                    <DropdownItem href="/products#compression_spring">
                      Compression Spring
                    </DropdownItem>
                    <DropdownItem href="/products#conical_spring">
                      Conical Spring
                    </DropdownItem>
                    <DropdownItem href="/products#leaf_spring">
                      Leaf Spring
                    </DropdownItem>
                    <DropdownItem href="/products#pigtali_spring">
                    Pigtali Spring 
                    </DropdownItem>
                  
                  </DropdownMenu>
                </UncontrolledDropdown>

              </NavItem>

              <NavItem>
                <NavLink href="/quality">
                  Quality
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/home#contactus">
                  Contact Us
                </NavLink>
              </NavItem>
            
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigationbar;
