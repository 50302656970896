import React from "react";

// reactstrap components
import { Card, Container, Row, Col, CardTitle } from "reactstrap";

// core components

function OurMission() {
  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
        <Container fluid>
              <h2 className="title text-center pt-0">Mission/ Vision</h2>
              <div className="blogs-1 pt-0" id="blogs-1">
                <Row>
                  <Col className="ml-auto mr-auto" md="10">
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="5" className="text-center">
                          <div className="card-image">
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/images/tj/Mission_355x237.jpg")}
                            ></img>
                          </div>
                        </Col>
                        <Col md="7">
                          <CardTitle tag="h3" style={{textAlign: "justify"}}>
                              Our Mission
                          </CardTitle>
                          <p className="description" style={{ fontWeight:"400" , color:"black", textAlign: "justify" }}>
                          In the realm of TJ Suspension Systems, our mission is not just a statement; it's a guiding principle that fuels our daily pursuits. We strive to redefine the suspension industry by introducing cutting-edge and customized solutions that go beyond expectations. Our commitment is unwavering to provide springs and bars that are not only functional but set a new benchmark for innovation and precision.
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default OurMission;
