import React from "react";
import { a } from "react-router-dom/cjs/react-router-dom.min";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Products() {
  return (
    <>
      <div className="cd-section" id="features">
        
        
        
        <div className="mt-1" style={{ paddingTop:"0" }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Our Products</h2>
                <h5 className="description" style={{ color:'black', fontWeight:"400" }}>
                At TJ Suspension Systems, our extensive product range is not just a collection; it's a commitment to excellence. From the solidity of barrel springs to the precision of conical springs, we offer a comprehensive suite of solutions. Embrace reliability with TJ Suspension Systems – where quality meets innovation.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <a href="/products#block_barrel_spring">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Block-Barrel-Spring.jpg") + ")",
                    height:"200px"
                  }}
                >
                  <div className="info">
                  
                    <div className="description">
                    <h4 className="info-title">Block/ Barrel Spring</h4>
                    </div>
                  </div>
                </Card>
                </a>
              </Col>
              <Col md="4">
              <a href="/products#coil_spring">

                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Coil-Spring_355x237.jpg") + ")",
                      height:"200px"
                  }}
                >
                  <div className="info">
                  <h4 className="info-title">Coil Spring</h4>
                  </div>
                </Card>
                </a>
              </Col>
              <Col md="4">
              <a href="/products#compression_spring">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Compression-Spring.jpg") + ")",
                      height:"200px"
                  }}
                >
                  <div className="info">
                  <h4 className="info-title">Compression Spring</h4>
                    <div className="description">
                    </div>
                  </div>
                </Card>
                </a>
              </Col>
            </Row>

            <Row>
              <Col md="4">
              <a href="/products#conical_spring">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Conical-Spring_355x237.jpg") + ")",
                    height:"200px"
                  }}
                >
                  <div className="info">
                    <div className="description">
                      <h4 className="info-title">Conical Spring</h4>
                    </div>
                  </div>
                </Card>
                </a>
              </Col>

              <Col md="4">
              <a href="/products#leaf_spring">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Leaf-Spring_355x237.jpg") + ")",
                      height:"200px"
                  }}
                >
                  <div className="info">
                  <h4 className="info-title">Leaf Spring</h4>
                  </div>
                </Card>
                </a>
              </Col>
              
              <Col md="4">
              <a href="/products#pigtali_spring">

                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "url(" + require("assets/images/tj/Pigtail-Spring_355x237.jpg") + ")",
                      height:"200px"
                  }}
                >
                  <div className="info">
                  <h4 className="info-title">Pigtali Spring</h4>
                  </div>
                </Card>
                </a>
              </Col>

              
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Products;
