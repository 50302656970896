import React from "react";

// reactstrap components
import { Card, Container, Row, Col, CardTitle } from "reactstrap";

// core components

function OurVision() {
  return (
    <>
      <div className="cd-section pb-0" id="features">
        <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
        <Container fluid>
            <Col md="12"> 
              <div className="blogs-1 pt-0 pb-0" id="blogs-1">
                <Row>
                  <Col className="ml-auto mr-auto" md="10">
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="7" className="order-1">
                          <CardTitle tag="h3" style={{textAlign: "justify"}}>
                              Our Vision
                          </CardTitle>
                          <p className="description" style={{ fontWeight:"400" , color:"black", textAlign: "justify" }}>
                          Looking into the future, our vision at TJ Suspension Systems is expansive and visionary. We envision ourselves as the beacon of excellence in suspension technology, earning global recognition for our relentless dedication to quality, innovation, and customer satisfaction. We aim to be pioneers in shaping the future of mobility, constantly pushing boundaries to create a world where springs and bars are not just components but enablers of a smoother, safer, and more connected journey.
                         </p>
                          
                        </Col>
                        <Col md="5" className="text-center order-md-2">
                          <div className="card-image">
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/images/tj/Vision_355x237.jpg")}
                            ></img>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default OurVision;
