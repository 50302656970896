import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function AboutUsContact() {
  return (
    <>
      <div className="section section-nucleo-icons" style={{background:"#013c5c", color:"white" }}>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">Get in touch with us</h2>
              <h5 className="description" style={{ fontWeight:"400" , color:"white", textAlign: "justify" }}>
                {/* We would love to hear from you! If you have any inquiries or need more information, feel free to reach out to us. */}
                Let's connect and bring your vision to life! Whether you have inquiries or seek more information, we're here for you. Reach out and let's embark on a journey of collaboration and excellence.
              </h5>
              <Button
                className="btn-round mr-1"
                to="/home#contactus"
                target="_blank"
                color="info"
                tag={Link}
                size="lg"
              >
                Contact Us
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
                <i className="now-ui-icons ui-1_send"></i>
                <i className="now-ui-icons location_pin"></i>
                <i className="now-ui-icons arrows-1_share-66"></i>
                <i className="now-ui-icons location_pin"></i>
                <i className="now-ui-icons business_briefcase-24"></i>
                <i className="now-ui-icons business_globe"></i>
                <i className="now-ui-icons shopping_delivery-fast"></i>

                <i className="now-ui-icons location_pin"></i>
                <i className="now-ui-icons ui-1_email-85"></i>
                <i className="now-ui-icons ui-2_chat-round"></i>
                <i className="now-ui-icons education_hat"></i>
                <i className="now-ui-icons arrows-1_share-66"></i>
                <i className="now-ui-icons ui-2_like"></i>
                <i className="now-ui-icons location_world"></i>
                <i className="now-ui-icons emoticons_satisfied"></i>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUsContact;
