import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

import block_barrel_spring from 'assets/images/tj/Block-Barrel-Spring.jpg'; // Adjust the path accordingly
import coil_spring from 'assets/images/tj/Coil-Spring_355x237.jpg'; // Adjust the path accordingly
import compression_spring from 'assets/images/tj/Compression-Spring.jpg'; // Adjust the path accordingly
import conical_spring from 'assets/images/tj/Conical-Spring_355x237.jpg'; // Adjust the path accordingly
import leaf_spring from 'assets/images/tj/Leaf-Spring_355x237.jpg'; // Adjust the path accordingly
import pigtali_spring from 'assets/images/tj/Pigtail-Spring_355x237.jpg'; // Adjust the path accordingly



const products  = [
  {
    id : 1,
    title: "Block/ Barrel Spring",
    slug : "block_barrel_spring",
    image:block_barrel_spring,
    points: [
      "Solid and robust, designed for enduring resilience in various applications."
    ]
  },
  {
    id : 2,
    title: "Coil Spring",
    slug : "coil_spring",
    image:coil_spring,
    points: [
      "Versatile and dynamic, our coil springs ensure optimal performance and flexibility."
    ]
  },
  {
    id : 3,
    title: "Compression Spring",
    slug : "compression_spring",
    image:compression_spring,
    points: [
      "Engineered for resilience, our compression springs withstand pressure while maintaining form."
    ]
  },
  {
    id : 4,
    title: "Conical Spring",
    slug : "conical_spring",
    image:conical_spring,
    points: [
      "Tapered for precision, conical springs deliver strength with a focus on accuracy."
    ]
  },

  {
    id : 5,
    title: "Leaf Spring",
    slug : "leaf_spring",
    image:leaf_spring,
    points: [
      "Providing stability and load-bearing capability, our leaf springs are the foundation of robust structures."
    ]
  },
  
  
  {
    id : 6,
    title: "Pigtali Spring",
    slug : "pigtali_spring",
    image:pigtali_spring,
    points: [
      "Uniquely coiled for resilience, our pigtails springs offer reliability in diverse applications."
    ]
  },
 
  
];

function ProductsSection() {

  
  return (
    <>
      <div className="wrapper">
      <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
          <Container fluid>
              <Col md="12">
                <h2 className="title text-center">Products</h2>
                <div className="blogs-1 pt-0" id="blogs-1">
                  <Row>
                  <Col className="ml-auto mr-auto" md="10">
                  {products ?.map((product , index)=>(
                    <section id={product?.slug}>
                      <Card key={index} className="card-plain card-blog">
                        
                        <Row>
                          <Col md="5" className="text-center">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img img-raised rounded"
                                src={product?.image}
                                height={237}
                                width={355}
                              ></img>
                            </div>
                          </Col>
                          <Col md="7">
                            <h4 className="card-description mt-2" style={{ fontWeight:"700" , color:"black" }}>
                            {product.title}
                            </h4>
                            {product?.points.map((point, index)=>(
                            <p key={index} className="p-0 m-0" style={{ fontWeight:400, color: "black", textAlign: "justify"}}>{point}</p>
                            ))}
                          </Col>
                        </Row>
                        
                      </Card>
                      <hr/>
                      </section>
                      ))}
                    </Col>
                  </Row>
                </div>
              </Col>
          </Container>
        </div>
      </div>
    
    </>
  );
}

export default ProductsSection;
