import React from "react";

import Navigationbar from "components/Final/Headers/Navigationbar";
import Footer from "components/Final/Headers/Footer";
import AboutUsHeader from "./aboutus-sections/AboutUsHeader";
import AboutUsSection from "./aboutus-sections/AboutUsSection";
import OurMission from "./aboutus-sections/OurMission";
import OurVision from "./aboutus-sections/OurVision";
import OurTeams from "./aboutus-sections/OurTeams";
import AboutUsContact from "./aboutus-sections/AboutUsContact";
import WorkProcess from "./quality-section/WorkProcess";

function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Navigationbar />
      <div className="wrapper">
        <AboutUsHeader />
        <AboutUsSection/>
        <OurMission/>
        <OurVision/>
        {/* <WorkProcess/> */}
        <AboutUsContact/>
       
       
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
