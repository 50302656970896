import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function AboutUs() {
  return (
    <>
      <div className="cd-section" id="features">
        

      <div className="features-1" style={{ paddingTop:"0", paddingBottom:"0" }}>
    
          <Container fluid>
          
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Who we are</h2>
                <h5 className="description" style={{ fontWeight:"400" , color:"black" }}> 
                TJ Suspension Systems is your go-to for world-class springs and bars. We're not just a brand; we're your dedicated partner, exclusively focused on meeting your unique needs and delivering top-notch quality. 
                </h5>

              </Col>
              </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_send"></i>
                  </div>
                  <h4 className="info-title">OUR MISSION</h4>
                  <p className="description" style={{ fontWeight:"400" , color:"black"}}>
                  At TJ Suspension Systems, our mission to provide customers with the pinnacle of quality in customized coiled spring products and bright bars, meeting and exceeding their unique specifications.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <i className="now-ui-icons business_badge"></i>
                  </div>
                  <h4 className="info-title">OUR TEAM</h4>
                  <p className="description" style={{ fontWeight:"400" , color:"black"}}>
                  Behind our unprecedented success stands a powerhouse team of 50 highly qualified professionals and skilled technicians. Their dedication and expertise are the driving force propelling TJ Suspension Systems to new heights.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons business_bank"></i>
                  </div>
                  <h4 className="info-title">INFRASTRUCTURE</h4>
                  <p className="description" style={{ fontWeight:"400" , color:"black"}}>
                  With all manufacturing facilities in-house, we ensure an unmatched level of control, guaranteeing the highest quality and timely delivery of our products. At TJ, precision and excellence are not just standards; they're our commitment to you.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
        
      
        
      </div>
    </>
  );
}

export default AboutUs;
