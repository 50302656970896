import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import SocialPreFooter from "./SocialPreFooter";

// core components

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container fluid>
          <div className="content">
            <Row>
              <Col md="3" xs="12">
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src="/tj-logo-vertical.png" height="120" />
                </a>
            
              </Col>
              <Col md="3" xs="6" style={{ fontWeight:"400" , color:"black", textAlign: "justify" }}>
                <h5>Spring Products</h5>
                <ul className="links-vertical">
                  <li >
                    <a href="/products#block_barrel_spring">
                      Block/ Barrel Spring
                    </a>
                  </li>
                  <li>
                    <a href="/products#coil_spring">
                    Coil Spring
                    </a>
                  </li>
                  <li>
                    <a href="/products#compression_spring">
                      Compression Spring
                    </a>
                  </li>
                  <li>
                    <a href="/products#conical_spring">
                    Conical Spring
                    </a>
                  </li>
                  <li>
                    <a href="/products#leaf_spring">
                      Leaf Spring
                    </a>
                  </li>
                  <li>
                    <a href="/products#pigtali_spring">
                      Pigtali Spring 
                    </a>
                  </li>
                
                </ul>
              </Col>
              <Col md="3" xs="6">
                <h5>Quick Links</h5>
                <ul className="links-vertical">
                  <li>
                    <a href="/home">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/aboutUs">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="/quality">
                      Quality
                    </a>
                  </li>
                
                  <li>
                    <a href="/home#contactus">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="3" xs="12">
                <h5>CONTACT US</h5>
                <p className="description" style={{ fontWeight:"400", color: "black" }}>
                  U-63/ U-64, MIDC Industrial Area, Hingna<br/> 
                  Nagpur – 440016,<br></br>
                  Maharashtra, India<br></br>
                  +91-7104-242033
                </p>
              </Col>
            </Row>
          </div>
          <hr/>
          <div className=" text-center">
            Copyright © 2023 TJ Suspension Systems. All Rights Reserved.
          </div>
          <div className=" text-center mt-2">
            Developed by <a href="https://kalibaenterprises.com" style={{color: "black"}} target="_blank">Kaliba Enterprises Pvt. Ltd.</a> 
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
